.sw-selector > div:last-child {
    overflow: visible!important;
}
.min_filter{
    margin: 2em 0 1em 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.min_filter_hidden {
    display: none;
}
.selectAll.time_item{
    width: 26px;
    padding: 4px 0 4px 15px;
}
.col.checkbox_style {
    width: 24px;
}
.receiveTime.time_item{
    padding: 4px 4px 4px 15px;
}
.provider_title {
    font-weight: bold;
    letter-spacing: .6px;
    color: #333333;
}

.btn.bubleButton {
    width: 36px;
    height: 36px;
    font-size: 20px;
    min-width: 36px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0 5px;
    border: 1px solid #b9b9b9;
    transition: .4s;
}
.tbn_check-modal.active:hover,
.btn.bubleButton:hover {
    border: 1px solid #777777;
}
.productName {
    transition: .4s;
    color: #333333;
    font-weight: bold;
    outline: none;
}
.productName.Link:hover,
.productName.Link:focus{
    color: #31a0ff;
}
.tbn_check-modal {
    width: 36px;
    height: 36px;
    font-size: 20px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid transparent;
    transition: .4s;
    margin: 0 25%;
    color: #ff9800;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: .5;
}
.tbn_check-modal.active {
    border: 1px solid #b9b9b9;
    opacity: 1;
}
.tab_nav.bg_transparent {
    background-color: transparent;
    box-shadow: none;
    padding: 1.5em 0 0 2em;
}
.Tab_btn.Btn_sort{
    min-width: 70px;
    border-radius: 30px!important;
    border: 1px solid #adadad!important;
    background-color: transparent!important;
    color: #212121;
    padding: 10px;
    margin: 5px;
    transition: .4s;
    font-size: 16px;
    text-transform: none;
    box-shadow: 0 0 7px 0px rgb(153 150 153);
}
.Tab_btn.Btn_sort.Tab_selected{
    border: 1px solid #ff9800!important;
    background-color: #ff9800!important;
    color: #fff;
    box-shadow: 0 0 6px 0px rgb(153 150 153); 
}
.Tab_btn:hover{
    border: 1px solid #ff9800!important;
    background-color: #ff9800!important;
    color: #fff;   
    box-shadow: 0 0 3px 0px rgb(153 150 153);

}
.Tab_btn.Btn_sort .MuiTab-label-337 {
    font-size: 16px;
}
.postProvider.lable{
    padding: 5px;
    color: #fff;
    border-radius: 8px;
    border: 1px solid #ff9800!important;
    background-color: #ff9800!important;
    box-shadow: 0 0 6px 0px rgb(153 150 153);
}

.collect .dashTable .postProvider.item, 
.collect .dashTable .postProvider.item *{
    color: transparent;
    background-color: transparent;
}

.actionStatus{
    width: 160px;
}
.selectSyle {
    border-radius: 4px;
    position: relative;
    background-color: transparent;
    border: 1px solid #ced4da;
    font-size: 16px;
    padding: 10px 0 10px 12px;
    margin-left: 15px;
    /* transition: border-color,box-shadow, */
}
.selectSyle:focus {
  border-radius: 4px;
  border-color: #80bdff;
  box-Shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.selectSyle::before,
.selectSyle::after,
.selectSyleDisabled::before,
.selectSyleDisabled::after,
.technicalProblem::before,
.technicalProblem::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    border: none!important;
    border-bottom: none!important;
    pointer-events: none;
}
.technicalProblem,
.selectSyleDisabled {
    border: 1px solid transparent;
    border-radius: 4px;
    position: relative;
    background-color: transparent;
    font-size: 16px;
    padding: 10px 0 10px 12px;
    margin-left: 15px;
}
.technicalProblem{
    box-shadow:inset 0 0 5px red!important;
}
.note_Style{
    width: 140px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #777777;
}
.quantity_input {
    width: 70px;
    height: 30px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #777777;

}
.save__BTN{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 0 0;
}
.mb-5{
    margin-bottom:5em;
}
.modalStyle {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    z-index: 420;
    background-color: rgba(0,0,0,.4);
    position: absolute;
  }



.modalContainter {
    width: 100%;
    padding: 12px;
    background-color:#fff;
    border: 2px solid #fff;
    border-radius: 12px;
    outline: 'none';
}
.modalRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
}
.rowButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 0 0;
}
.rowButtons.center{
    justify-content: center;
}
.rowButtons.space_around {
    justify-content: space-around;
}
.modalTitle {
    width: 100%;
    font-size: 20px;
    color: #191919;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center; 
}
.subTitle {
    width: 100%;
    font-size: 16px;
    color: #191919;
    margin: -3px 0 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quantity {
    width: 100%;
    font-size: 20px;
    color: #191919;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    overflow: hidden;
}
.modalBtn {
    width: 45%;
    font-size: 14px;
    color: #191919;
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 6px;
    background-color: #cce6ff!important;
    box-shadow: 0 0 3px 1px #b9b9b9!important;
}
.modalBtn:hover {
    background-color: #cce6ff!important;
    box-shadow: 0 0 5px 3px #b9b9b9!important;
}
.closeBtn {
    width: 45%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 6px;
    box-shadow: 0 0 3px 1px #b9b9b9!important;
    color: #fff!important;
    background-color: #e91e63!important;
}
.closeBtn:hover {
    background-color: #e91e63!important;
    box-shadow: 0 0 5px 3px #848484!important;
}
.saveBtn {
    width: 45%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    box-shadow: 0 0 3px 1px #b9b9b9!important;
    color: #fff!important;
    background-color: #9c27b0!important;
}
.saveBtn:hover {
    background-color: #9c27b0!important;
    box-shadow: 0 0 5px 3px #848484!important;
}
.saveBtn.disabled{
    opacity: .5!important;
}
.note {
    padding: 10px;
    color: #191919;
    font-weight: bolder;
    font-size: 16px;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    box-shadow: 0 0 3px 1px #b9b9b9;
}
.note::-webkit-input-placeholder,
.note::-moz-placeholder,
.note::-moz-placeholder,
.note:-ms-input-placeholder,
.note::placeholder {
   font-weight: 400;
   color: #808080; 
}
.modal_cols.dalRow{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1em 0.3em;
}


.modal_cols.dalRow .modal_tbl {
    width: 48%;
    box-sizing: border-box;
}

.col_title{
    font-size: 16px;
    font-weight: 700;
}
.col_text_note{
    line-height: 1.1;
    font-size: 14px;
    font-weight: bold;
    color: #191919;
    margin: 3px 0 3px 0;
}
.col_text_role{
    margin: 0;
    font-size: 12px;
    line-height: 1.1;
    font-weight: bold;
    color: #ff9800;
}
.col_text_time{
    margin: 0;
    line-height: 1.1;
    font-size: 11px;
    font-weight: bold;
    color: #9c27b0;
}
.packageItemBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    outline: none;
    background-color: transparent;
}
.packageItemBlock.disabled {
    position: relative;
}
.packageItemBlock.disabled::before {
    content: '';
    width: 120px;
    height: 50px;
    left: -10px;
    top: -10px;
    z-index: 30;
    position: absolute;
}
.packageItemError{
    color: red;
    font-size: 18px!important;
}

.yellowOrderId{
    text-align: center;
    padding: 3px 4px;
    cursor: pointer;
    border-radius: 3px;
    background-color: yellow;
}
.blueOrderId {
    text-align: center;
    padding: 3px 4px;
    cursor: pointer;
    border-radius: 3px;
    background-color: #7388ff; 
}
.blueOrderId:hover,
.yellowOrderId:hover {
    box-shadow: 0px 0px 6px #c8c8c8;
}
.provider_color {
    color: #9c27b0;
}
.branch_color{
    color: #7388ff; 
}
.label__block{   
    border-bottom: 1px solid #D2D2D2;
}
.label__name {
    font-size: 10px;
}
.table__style th,
.table__style td{
    width: calc(100% / 7);
    padding: 4px!important;
}
.download_file.btn_file{
    position: absolute;
    right: 15px;
    padding: 0 1em;
    margin: 0;
    color: #fff;
    background-color: #00ccff!important;
    cursor: pointer;
}
.download_file.btn_file:hover{
    background-color: #039ac0!important;
}
.file-upload {
	position: relative;
    float: right;
    margin: 6px 0;
}
.file-upload__label {
    display: flex;
    padding: .5em 2.6em;
    color: #fff;
    background: #9c27b0;
    border-radius: .4em;
    transition: .3s;
    align-items: center;
    opacity: .8;
    cursor: pointer;
}
.file-upload__label:hover {
    background: #7a0c8d;
}
.upload_file_name.file_name {
    display: flex;
    color:  #9c27b0;
    font-size: 18px;
    font-weight: 800;
    border-radius: .4em;
    transition: .3s;
    align-items: center;
}
.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width:0;
    height: 100%;
    opacity: 0;
}
.upload_successStatus.success_block{
    width: 100%;
    max-height: 190px;
    margin: 20px!important;
    padding: 15px!important;
    border-radius: 12px;
    padding-right: 0!important;
    background: #c3f5aa80;
    box-shadow: 0 0 6px #59ff00f7;
}
.upload_successStatus.success_block.z_100_fixed{
    width: 320px;
    margin: 10px auto!important;
    bottom: 40px!important;
    position: fixed;
    z-index: 100;
    background: #c3f5aa;
}
.upload_error_sms.error_block {
    width: 100%;
    max-height: 190px;
    margin: 20px!important;
    border-radius: 12px;
    padding-right: 0!important;
    background: #ff00000d;
    box-shadow: 0 0 6px #ff000099;
}
.upload_error_list{
    width: 100%;
    max-height: 128px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 53px;  
}
.upload_error_list::-webkit-scrollbar { width: 10px; }
.upload_error_list::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey; border-radius: 10px; }
.upload_error_list::-webkit-scrollbar-thumb { background: red; border-radius: 10px;  }
.upload_error_list::-webkit-scrollbar-thumb:hover { background: #b30000; }
.upload_error_sms > h4{
    color: red;
}
.recommendation_block {
    width: 200px;
    margin-top: -50px;
}
.recommendation_block.admin {
    width: 200px;
    margin-top: -25px;
    /* margin-bottom: 12px; */
}
.recommendation_block.admin legend{
    color: black;
    margin-bottom: 0;
}
.recommendation_block.admin p{
    font-size: 18px;
}

.recommendation_block.admin .recomendation_note {left: 200px;top:-20px}

.recomendation_btn.btn-annim{
    padding: 10px;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    font-size: 16px;
    color: seagreen;
    cursor: pointer;
}
.recommendation_block:hover .recomendation_note{
    display: block;
}
.recomendation_icon.icon-annim {
    margin: 10px;
    display: flex;
    align-items: center;
}

.recomendation_note {
    display: none;
    width: 600px;
    right: 15px;
    top: 5px;
    font-weight: bold;
    padding: 12px;
    border-radius: 8px;
    background-color: #ffe6e6;
    box-shadow: 0 0 6px rgba(0,0,0,.4);
    position: absolute;
    z-index: 11;
    transition: .4s;
}
.excel {
    color: forestgreen;
}
.add_line{
    color: red;
}

.order__hader_block .recommendation_block{
    margin-top: 6px;
}

.order__hader_block .recomendation_note {
    left: 15px;
    top: -10px;
}
.order__table_btn_block{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn_row.btn_ {
    padding: 10px 20px;
    margin: 5px;
}
.btn_row.btn_  > *{
    color: #fff;
    font-size: 28px;
    font-weight: 800;
}
.btn_row.add_btn {
    background-color: #8cc1a3;
}
.btn_row.add_btn:hover {
    background-color: #2f9e5f;
}
.btn_row.remove_btn{
    background-color: #f7a7a7;
}
.btn_row.remove_btn:hover{
    background-color: #f96161;
}
.th_style .col_style.col_border {
    border-left: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    box-sizing: border-box;
}
.col_style.col_border {
    border-left: 1px solid #d2d2d2;
    box-sizing: border-box;
}
.goBack_btn_link {
    position: relative;
    z-index: 20;
    cursor: pointer;
    transition: .4s;
    opacity: .7;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.goBack_btn_link.creat {
    position: absolute;
    top: 90px;
    left: 35px;
}
.goBack_btn_link:hover {
    opacity: 1;
}
.goBack_btn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 0 4px 3px #b9b9b9;
    background-color: #e91e63;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
}
.goBack_btn_link span{
    font-size: 14px;
    color: #e91e63;
    margin: -6px 0 0 10px;
}
.provider__inp {
    width: 100%;
    height: 35px;
    padding: 5px;
    margin: 0;
    font-size: 16px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #adadad;
}
.provider__inp.mm::selection {
    display: none!important;
}
.provider__inp:focus,
.provider__inp:active {
    border: 1px solid #06b954;
}
/* Chrome, Safari, Edge, Opera */
.provider__inp::-webkit-outer-spin-button,
.provider__inp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.provider__inp[type=number] {
  -moz-appearance: textfield;
}

.distict_prices_card {
    overflow: hidden;
}
.distict_prices_cardBody {
    max-height: 70vh;
    overflow: auto;
}
.distict_prices_cardBody tr:nth-child(2n) {
    background-color: rgba(242, 242, 242, .5);
}
.distict_prices_cardBody tr td:nth-child(2n) {
    background-color: rgba(242, 242, 242, .5);
}
.max_value.max_item{
    max-width: 400px;
    overflow-wrap: break-word;
}
.provider_district_selected {
    width: 25px;
    height: 25px;
    margin-right: 25px;
}
.provider_table.tb .rt-td {
    overflow: visible;
}
.line_name{
    display: flex;
    align-items: center;
    flex-direction: row;    
}
.line_name .provider_no_info{
    display: flex;
    justify-content: flex-end;
}
.provider_no_info {
    width: 20px;
    height: 20px;
    position: relative;
    transition: .4s;
    cursor: pointer;

}
.provider_no_info:hover .provider_no_info_txt {
    width: 200px;
    padding: 10px;
    top: -10px;
    left: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 120;
    position: absolute;
    border-radius: 8px;
    background-color: #ffe6e6;
    box-shadow: 0 0 6px #ff000099;
    cursor: pointer;
}
.provider_no_info_txt {
    display: none;
    transition: .4s;
}
.provider_no_info_txt p{
    font-size: 14px;
    color: black;
    margin: 0;
    width: 200px;
    word-break: break-all;
    white-space: normal;
}
.provider_no_info .icon {
    color: red;
    animation: iconAnim 2s infinite;
   
}
@keyframes iconAnim {
    0% {opacity: .3;}
    100% {opacity: 1;}
}

.mu_select.style{
    min-width: 200px;
    margin: 40px auto;
}
.mu_select.style.mg0{
    min-width: 170px;
    margin: 0 auto;
}
.mu_select.style div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.mu_select.item {
    padding: 6px 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.not_itemMetrics {
    width: 100%;
    margin: 40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.not_itemMetrics .col_text_role{
    font-size: 18px;
}
.itemMetrics_link {
    border: 1px solid #9c27b0;
    border-radius: 12px;
    padding: 20px;
    margin: 60px 0 0 0;
    font-size: 16px;
    transition: .4s;
}
.itemMetrics_link:hover {
    background-color: #9c27b0;
    color: #fff;
}
.copyMakeAction{
    width: 100%;
    position: static;
    z-index: 1000;
    background-color: red;
    color: #fff;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.copyMakeAction *{
    margin: 0;
}
.error_note {
    color: red;
    font-weight: 500;
    font-size: 14px;
}
.pass__line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.pass__line .icon_btn{
    padding: 5px 8px;
    box-shadow: 0 0 4px #9c27b0;
    border-radius: 50%;
}
.pass__line .icon_btn:hover{
    cursor: pointer;
    background-color: #ffe8ff;
    box-shadow: 0 0 2px #9c27b0;
}


.pass__line svg {
    color: #9c27b0;
}


.rdt.rdtOpen .rdtPicker {
    width: 180px;
    border-radius: 6px;
}

.rdt.rdtOpen .rdtPicker .rdtDays {
    width: 100%;
}
.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev {
    line-height: 23px;
}
.rdtDays tr .dow {
    padding-bottom: 1px;
    padding-top: 1px;
}
.rdtDay {
    height: 18px;
    line-height: 20px;
    width: 18px;
}